.accordion-container {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  background: #fff;

}

.accordion-header {
  background-color: #fff;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-items: center;
  justify-content: space-between;
  transition: 0,9 ease;
}

.icon {
  float: right;
  margin-top: 2px;
  transition: transform 0.3s;
}

.rotate {
  transform: rotate(45deg);
}

.accordion-body {
  padding: 10px;
  width: 318px;
}
